@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
@font-face {
  font-family: 'RuneScapeRegular'; /* Choose a name for your font */
  src: url('../../public/RuneScape-Bold-12.ttf');
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 14px;
}

*,
*:before,
*:after,
*:placeholder-shown {
  font-family: inherit;
}

.runescape{
  font-family: 'RuneScapeRegular'; 
}
