@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100%;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: 'RUNES'; /* Choose a name for your font */
  src: url('../public/runescape_uf.ttf');
}


