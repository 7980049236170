* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.login-container {
  height: 100%;
}
/* body {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
} */

.header-login {
  min-height: 12vh;
  width: 100%;
  background-color: #084da3;
  position: relative;
}
@media (max-width: 768px){
  .header-login {
    min-height: 10vh;
  }
}
.header-login::before {
  content: '';
  width: 100%;
  height: 7px;
  position: absolute;
  bottom: 0px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    color-stop(-33.4%, #084da3),
    color-stop(6.07%, #084da3),
    color-stop(49.56%, #ffffff),
    color-stop(82.59%, #084da3),
    color-stop(121.25%, #084da3)
  );
  background: linear-gradient(
    270deg,
    #084da3 -33.4%,
    #084da3 6.07%,
    #ffffff 49.56%,
    #084da3 82.59%,
    #084da3 121.25%
  );
}

.footer-login {
  min-height: 12vh;
  width: 100%;
  background-color: #084da3;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: right;
}

@media (max-width: 768px){
  .footer-login {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: static;
    padding-bottom: 10px;
    min-height: 10vh;
  }
}

.footer-login::before {
  content: '';
  width: 100%;
  height: 7px;
  position: absolute;
  -ms-flex-item-align: start;
  align-self: flex-start;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    color-stop(-33.4%, #084da3),
    color-stop(6.07%, #084da3),
    color-stop(49.56%, #ff9933),
    color-stop(82.59%, #084da3),
    color-stop(121.25%, #084da3)
  );
  background: linear-gradient(
    270deg,
    #084da3 -33.4%,
    #084da3 6.07%,
    #ff9933 49.56%,
    #084da3 82.59%,
    #084da3 121.25%
  );
}
@media (max-width: 768px){
  .footer-login {
    position: static;
    top: 0px;
  }
}

.footer-login .btn {
  margin-left: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 768px){
  .footer-login .btn {
    margin-left: 0px;
    margin-top: 15px;
  }
}

.footer-login .btn button {
  width: 40px;
  height: 40px;
  background-color: #e55022;
  border: 1px solid #fff;
  -webkit-box-shadow: inset 4px 2px 8px rgba(255, 255, 255, 0.6),
    inset -2px -3px 5px #aa2300;
  box-shadow: inset 4px 2px 8px rgba(255, 255, 255, 0.6),
    inset -2px -3px 5px #aa2300;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}
@media (max-width: 768px){
  .footer-login .btn button {
    width: 40px;
    height: 40px;
  }
}

.footer-login .btn p {
  color: #fff;
  margin-left: 20px;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}

.footer-login .informations {
  margin-right: 50px;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: 400;
}
@media (max-width: 768px){
  .footer-login .informations {
    width: 100%;
    margin-right:0px;
  }
}


main {
  height: 76vh;
  background: radial-gradient(
    19.48% 42.48% at 10% 22.48%,
    #9cc0e9 0%,
    #508fd9 100%
  );
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr auto 1fr;
  grid-template-columns: 1fr auto 1fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

}
@media (max-width: 768px){
  main {
    display: flex;
    flex-direction: column;
    height: 100vh
  }
}
main .img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  /* top: -40px; */
}
@media (max-width: 768px){
  main .img {
    width: 100%;
    align-items: center;
    top: 20px
  }
}
main .img h1 {
  font-family: 'Source Sans Pro', sans-serif;
  color: white;
  font-weight: 500;
  margin-top: 30px;
  margin-right: 40px;
}
@media (max-width: 768px){
  main .img h1 {
    width:100%;
    margin: 0px;
    margin-top: 20px;
    text-align:center;
  }
}

main .line {
  width: 2px;
  height: 80%;
  margin: 0 42px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#508fd9),
    color-stop(47.4%, #ffffff),
    color-stop(98.96%, #508fd9)
  );
  background: linear-gradient(
    180deg,
    #508fd9 0%,
    #ffffff 47.4%,
    #508fd9 98.96%
  );
}
@media (max-width: 768px){
  main .line {
    display: none;
  }
}
@media (max-width: 768px){
  main .users {
    margin-top: 50px;
    padding: 10px;
    width: 100%;
  }
}

main .users section {
  padding: 15px 20px;
  width: 445px;
  height: 112.5px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#084da3),
    to(#508fd9)
  );
  background: linear-gradient(90deg, #084da3 0%, #508fd9 100%);
  border-radius: 4px 0px 0px 4px;
  position: relative;
  background: url('../../public/login/Rectangle\ 73\ \(1\).png');
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

}
@media (max-width: 768px){
  main .users section {
    width: 100%;
    margin: 0px;
  }
}
main .users section .iconA,
main .users section .iconB {
  width: 81px;
  height: 81px;
  border: 2px solid #fff;
  border-radius: 4px;
}

main .users section .iconA:hover,
main .users section .iconB:hover {
  border-color: #ffcc00;
}

main .users .lucas .iconA {
  background: url('../assets/admin.png') no-repeat center center;
  background-size: cover;
}

main .users .lucas .user {
  margin-left: 20px;
  position: relative;
  color: #fff;
}

main .users .lucas .user h3 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 20px;
}

main .users .lucas .user p {
  font-family: 'Verdana', sans-serif;
  margin-top: 20px;
  font-size: 13px;
}

main .users .lucas .user .input {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  bottom: -24px;
}

main .users .lucas .user .input input {
  padding: 0 0.4rem;
  outline: none;
  height: 40px;
  width: 200px;
  background: #ffffff;
  border: 0.5px solid #333333;
  border-radius: 4px;
}

main .users .lucas .user .input .green {
  width: 40px;
  height: 40px;
  background-color: #23b221;
  border: 1px solid #fff;
  -webkit-box-shadow: inset 4px 2px 8px rgba(255, 255, 255, 0.6),
    inset -2px -3px 5px #267c08;
  box-shadow: inset 4px 2px 8px rgba(255, 255, 255, 0.6),
    inset -2px -3px 5px #267c08;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 10px;
  position: relative;
}

main .users .lucas .user .input .green img {
  position: absolute;
  top: 7px;
}

main .users .lucas .user .input .blue {
  width: 40px;
  height: 40px;
  background-color: #2266f5;
  border: 1px solid #fff;
  -webkit-box-shadow: inset 4px 2px 8px rgba(255, 255, 255, 0.6),
    inset -2px -3px 5px #0844c3;
  box-shadow: inset 4px 2px 8px rgba(255, 255, 255, 0.6),
    inset -2px -3px 5px #0844c3;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

main .users .lucas .user .input .blue img {
  position: absolute;
  top: 5px;
}

main .users .crystal {
  margin-top: 40px;
  opacity: 1;
}

main .users .crystal .iconB {
  background: url('../assets/guest.png') no-repeat center center;
  background-size: cover;
}

main .users .crystal .user {
  margin-left: 20px;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
}
